












import Vue from 'vue';

export default Vue.extend({
  props: ['items', 'active'],
});
