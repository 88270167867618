













import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class ArtistTile extends Vue {
  @Prop() artist!: any;
}
